<script lang="ts" setup>
import type { FieldError } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import {
  useGlobals,
  useModal,
  useWebsiteOwnershipTransfer,
} from '@/composables';
import { useDeveloperToolsWebsites } from '@/composables/developerTools/useDeveloperToolsWebsites';
import { WEBSITE_OWNERSHIP_TRANSFER_EXPIRATION_LEN } from '@/data/globalConstants';
import { websiteRepo } from '@/repositories';
import { useProfileStore } from '@/stores/profile/profileStore';
import { AmplitudeEvent, FieldType } from '@/types';
import type { ModalEmits, ProWebsiteType, TypedFormValues } from '@/types';

type Props = {
  data: {
    website?: ProWebsiteType;
    onSuccess?: () => void;
  };
};
type FormValues = { email: string; website: string };

const emits = defineEmits<ModalEmits>();
const props = defineProps<Props>();
const { website } = props.data;
const isWebsitePreSelected = !!website;

const { closeModal: close, currentStep, goToNextStep } = useModal(emits);
const { t, amplitudeV2 } = useGlobals();
const profileStore = useProfileStore();
const { enabledDeveloperToolsWebsites } = useDeveloperToolsWebsites();

const email = ref('');
const selectedDomain = ref('');
const newOwnerEmail = ref('');
const hasConfirmedTransfer = ref(false);

const isSubmitting = ref(false);

const customEmailError = ref<FieldError | null>(null);
const customWebsiteError = ref<FieldError | null>(null);

const domain = ref('');
const database = ref('');

const availableWebsites = computed(() => {
  if (isWebsitePreSelected) {
    return [];
  }

  return enabledDeveloperToolsWebsites.value.map(({ website }) => ({
    label: website,
    value: website,
  }));
});

const websiteSchema = computed(() => ({
  type: FieldType.select,
  label: t('v2.search.or.select.website'),
  values: availableWebsites.value,
  validation: {
    required: true,
  },
}));

const emailSchema = computed(() => ({
  type: FieldType.text,
  label: t('Enter new owner’s email'),
  value: newOwnerEmail.value,
  validation: {
    required: true,
    email: true,
  },
}));

const isContinueDisabled = computed(() => {
  if (customEmailError.value || customWebsiteError.value) {
    return true;
  }

  if (!selectedDomain.value && !isWebsitePreSelected) {
    return true;
  }

  return !email.value;
});

const handleFormSubmit = async ({
  values,
  isFormValid,
}: TypedFormValues<FormValues>) => {
  if (!isFormValid) return;
  isSubmitting.value = true;
  await submitFirstStep({ ...values });
  isSubmitting.value = false;
};

const submitFirstStep = async ({
  email: emailFormValue,
  website: websiteFormValue,
}: FormValues) => {
  if (isContinueDisabled.value) {
    return;
  }
  const [recipientsEmailResponse, websiteToTransferResponse] =
    await Promise.all([
      getRecipientEmail(emailFormValue),
      getWebsiteToTransfer(websiteFormValue),
    ]);

  const recipientsEmail = recipientsEmailResponse;
  const websiteToTransfer = websiteToTransferResponse;

  if (!recipientsEmail || !websiteToTransfer) return;

  newOwnerEmail.value = recipientsEmail;
  domain.value = websiteToTransfer.website;
  database.value = websiteToTransfer.wordpressData?.databaseName ?? '';

  amplitudeV2(
    AmplitudeEvent.Websites.WEBSITE_TRANSFER_OWNERSHIP_EMAIL_PROVIDED,
  );
  goToNextStep(1);
};

const getRecipientEmail = async (email: string) => {
  const isEligible = await getIsNewOwnerEligible(email);

  if (!isEligible) {
    customEmailError.value = {
      text: t('v2.account.with.this.email.doesnt.exist'),
    };

    return;
  }

  return email;
};

const getWebsiteToTransfer = async (websiteDomain?: string) => {
  if (websiteDomain === undefined) {
    return website;
  }

  const [{ data }, err] = await websiteRepo.getProList({
    search: websiteDomain,
    page: 1,
    perPage: 1,
  });

  const websiteToTransfer = data?.data?.[0] as ProWebsiteType;

  if (err || !websiteToTransfer) {
    customWebsiteError.value = {
      text: t('Website not found'),
    };

    return;
  }

  const { transferDenialReason } =
    useWebsiteOwnershipTransfer(websiteToTransfer);

  if (transferDenialReason.value) {
    customWebsiteError.value = {
      text: t(transferDenialReason.value),
    };

    return;
  }

  return websiteToTransfer;
};

const handleFormChange = ({ values }: TypedFormValues<FormValues>) => {
  const { email: emailFormValue, website: websiteFormValue } = values;
  if (email.value !== emailFormValue) {
    customEmailError.value = null;
  }
  if (selectedDomain.value !== websiteFormValue) {
    customWebsiteError.value = null;
  }

  email.value = emailFormValue;
  selectedDomain.value = websiteFormValue;
};

const getIsNewOwnerEligible = async (email: string) => {
  const [{ data }, error] = await websiteRepo.getOwnershipTransferEligibility({
    email,
  });

  if (error) return false;

  return data.eligible;
};

const sendTransferRequest = async () => {
  if (!database.value || !domain.value) {
    return;
  }
  const requestData = {
    email: newOwnerEmail.value,
    databases: [database.value],
  };
  const config = {
    domain: domain.value,
  };

  const [_, error] = await websiteRepo.postTransferRequest({
    data: requestData,
    config,
  });
  if (!error) {
    amplitudeV2(
      AmplitudeEvent.Websites.WEBSITE_TRANSFER_OWNERSHIP_REQUEST_SENT,
    );
    goToNextStep(2);
    props.data?.onSuccess?.();
  }
};

(() => {
  if (!isWebsitePreSelected) return;
  domain.value = website.website;
  database.value = website.wordpressData?.databaseName ?? '';
})();
</script>

<template>
  <div class="transfer-ownership-modal">
    <template v-if="currentStep === 0">
      <div class="h-mb-8 transfer-ownership-modal__title">
        <h2 class="text-heading-2">{{ t('Transfer Ownership') }}</h2>
      </div>
      <p class="h-mb-16">
        {{
          t(
            'By transferring the ownership, you agree that the new contact will become the owner of the website and authoritative point of contact',
          )
        }}
      </p>

      <HSnackbar variant="info" class="h-mb-16">
        <span>
          {{
            t('Make sure the new owner has an account in {brand}', {
              brand: profileStore.clientBrandTitle,
            })
          }}
        </span>
      </HSnackbar>

      <div>
        <HForm
          validate-silently-on-mounted
          @on-submit="handleFormSubmit($event)"
          @on-change="handleFormChange"
        >
          <HFormField
            v-if="!isWebsitePreSelected"
            name="website"
            :schema="websiteSchema"
            class="h-mb-16"
            :custom-error="customWebsiteError"
            data-qa="website-selector"
          />

          <HFormField
            class="h-mb-16"
            :schema="emailSchema"
            name="email"
            :custom-error="customEmailError"
          />
        </HForm>
      </div>

      <HModalActions
        no-padding
        close-text="Cancel"
        class="h-mt-24"
        @close="close"
      >
        <HButton
          :is-loading="isSubmitting"
          h-form-submit
          :is-disabled="isContinueDisabled"
          :class="{
            'transfer-ownership-modal__transfer-cta--disabled':
              isContinueDisabled,
          }"
        >
          {{ t('Continue') }}
        </HButton>
      </HModalActions>
    </template>
    <template v-if="currentStep === 1">
      <h2 class="text-heading-2 h-mb-8">{{ t('Transfer Summary') }}</h2>
      <p>
        {{
          t(
            'Website transfer includes site files and connected databases. Domain won’t be transferred automatically.',
          )
        }}
      </p>
      <div class="transfer-ownership-modal__info-box h-mt-24">
        <Trans tag="p" :translate-params="{ domain }">
          Website:
          <b>{domain}</b>
        </Trans>
        <Trans tag="p" :translate-params="{ email: newOwnerEmail }">
          New owner:
          <b>{email}</b>
        </Trans>
      </div>

      <div
        class="transfer-ownership-modal__checkbox h-mt-24"
        @click="hasConfirmedTransfer = !hasConfirmedTransfer"
      >
        <hp-checkbox :active.prop="hasConfirmedTransfer" />
        <Trans
          tag="p"
          :translate-params="{ email: newOwnerEmail }"
          class="text-dark"
        >
          I confirm and understand that after transfer is done, {email} will be
          the new owner of the website and the website will be removed from my
          account
        </Trans>
      </div>
      <HModalActions
        no-padding
        close-text="Cancel"
        class="h-mt-24"
        @close="close"
      >
        <HButton
          :is-disabled="!hasConfirmedTransfer"
          @click="sendTransferRequest"
        >
          {{ t('Transfer ownership') }}
        </HButton>
      </HModalActions>
    </template>
    <div v-if="currentStep === 2" class="transfer-ownership-modal__success">
      <div class="transfer-ownership-modal__success-icon h-mb-24">
        <HImage src="@/images/onboarding/illustrations/checkmark.svg" alt="" />
      </div>

      <h2 class="text-heading-2">{{ t('v2.transfer.request.sent') }}</h2>
      <Trans
        tag="p"
        :translate-params="{
          email: newOwnerEmail,
          days: WEBSITE_OWNERSHIP_TRANSFER_EXPIRATION_LEN,
        }"
      >
        {email} needs to accept the transfer via the email invite. You will get
        an email when the transfer is complete.
        <b>Request will expire in {days} days.</b>
      </Trans>
      <div>
        <HButton class="h-mt-24" @click="close">{{ t('Close') }}</HButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.transfer-ownership-modal {
  &__title {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  &__info-box {
    padding: 16px;
    background-color: var(--gray-light);
    border-radius: 4px;
    p {
      color: var(--text-dark);
    }
  }
  &__checkbox {
    display: flex;
    gap: 8px;
  }
  &__success-icon {
    margin: 0 auto;
    height: 48px;
    width: 48px;
  }
  &__success {
    margin: 36px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  &__transfer-cta {
    &--disabled {
      cursor: default;
    }
  }
}
</style>
